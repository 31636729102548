import { db } from '../../config/firebase';
import { ref, onValue, set, update, remove, onChildChanged, orderByChild, equalTo, get, query } from 'firebase/database';
// let xlsx = require("json-as-xlsx");

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES DE BASE DE DATOS-------------------------------//
//--------------------------------------------------------------------------------------//

// leer datos
export const getCollections = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val());
	}
	return data;
};

export const getCollectionsComplete = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = dataSnapshot.val();
	}
	return data;
};

// set datos
export const setData = async (collection, id, json) => {
	set(ref(db, `${collection}/${id}`), json);
};

// buscar dato por id
export const searchData = async (collection, id) => {
	let json = {};
	const dbRef = ref(db, `${collection}/${id}`);
	onValue(dbRef, (snapshot) => {
		if (snapshot.exists()) {
			json = snapshot.val();
			// console.log(snapshot.val());
		}
	});
	// console.log(json);
	return json;
};

// buscar dato por valor
export const searchDataByValue = async (collection, key, value) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`), orderByChild(`${key}`), equalTo(`${value}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val())[0];
	}
	return data;
};

// actualizar datos
export const updateData = async (collection, id, json) => {
	update(ref(db, `${collection}/${id}`), json);
};

// borrar datos
export const deleteData = async (collection, id) => {
	remove(ref(db, `${collection}/${id}`));
};

// child changed
export async function childChange(collection) {
	let json = {};
	const collectionRef = ref(db, `${collection}/`);
	onChildChanged(collectionRef, (data) => {
		json = data.val();
	});
	return json;
}

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES GENERALES--------------------------------------//
//--------------------------------------------------------------------------------------//

function quitarAcentos(cadena) {
	const acentos = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
		Ñ: 'n',
		ñ: 'n',
	};
	return cadena
		.split('')
		.map((letra) => acentos[letra] || letra)
		.join('')
		.toString();
}

export function cambiarTextos(string) {
	const minusculas = string.toLowerCase();
	const sinEspacios = minusculas.replace(' ', '-');
	const sinTildes = quitarAcentos(sinEspacios);
	return sinTildes;
}

export function stringToArray(string, separador = ',') {
	const array = string.split(separador);
	return array;
}

// export function capitalize(string) {
//   const resultado = string.charAt(0).toUpperCase() + string.slice(1);
//   return resultado;
// }

export function cualMes(id) {
	let mes = '';
	switch (id) {
		case '01':
			mes = 'Enero';
			break;
		case '02':
			mes = 'Febrero';
			break;
		case '03':
			mes = 'Marzo';
			break;
		case '04':
			mes = 'Abril';
			break;
		case '05':
			mes = 'Mayo';
			break;
		case '06':
			mes = 'Junio';
			break;
		case '07':
			mes = 'Julio';
			break;
		case '08':
			mes = 'Agosto';
			break;
		case '09':
			mes = 'Septiembre';
			break;
		case '10':
			mes = 'Octubre';
			break;
		case '11':
			mes = 'Noviembre';
			break;
		case '12':
			mes = 'Diciembre';
			break;

		default:
			break;
	}
	return mes;
	// let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
	// return mes[id];
}

export function getDate() {
	const today = new Date();
	const year = today.getFullYear().toString();
	let month = (today.getMonth() + 1).toString();
	let day = today.getDate().toString();
	let hours = today.getHours();
	let minutes = today.getMinutes();
	let seconds = today.getSeconds();
	const meridian = hours < 12 ? 'am' : 'pm';
	if (month.toString().length === 1) {
		month = '0' + month;
	}
	if (day.toString().length === 1) {
		day = '0' + day;
	}
	if (hours.toString().length === 1) {
		hours = '0' + hours;
	}
	if (minutes.toString().length === 1) {
		minutes = '0' + minutes;
	}
	if (seconds.toString().length === 1) {
		seconds = '0' + seconds;
	}
	let date = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${meridian}`;
	return date;
}

export function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function setFavicons(favImg) {
	const headTitle = document.querySelector('head');
	const setFavicon = document.createElement('link');
	setFavicon.setAttribute('rel', 'shortcut icon');
	setFavicon.setAttribute('href', favImg);
	headTitle.appendChild(setFavicon);
}

export function downloadFile(file, name) {
	const link = document.createElement('a');
	link.href = `${file}`;
	link.download = `${name}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function download_file(fileURL, fileName) {
	// for non-IE
	if (!window.ActiveXObject) {
		var save = document.createElement('a');
		save.href = fileURL;
		save.target = '_blank';
		var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
		save.download = fileName || filename;
		if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
			document.location = save.href;
			// window event not working here
		} else {
			var evt = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false,
			});
			save.dispatchEvent(evt);
			(window.URL || window.webkitURL).revokeObjectURL(save.href);
		}
	}

	// for IE < 11
	else if (!!window.ActiveXObject && document.execCommand) {
		var _window = window.open(fileURL, '_blank');
		_window.document.close();
		_window.document.execCommand('SaveAs', true, fileName || fileURL);
		_window.close();
	}
}

// export function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export function top() {
	window.scrollTo(0, 0);
}

export function shuffle(array) {
	return array.sort((a, b) => 0.5 - Math.random());
}

// export function removeElementsByClass(className) {
// 	const elements = document.getElementsByClassName(className);
// 	while (elements.length > 0) {
// 		elements[0].parentNode.removeChild(elements[0]);
// 	}
// }

export async function userActive() {
	return await getCollectionsComplete(`usuarios/${sessionStorage.getItem('3c393f98')}`);
}
