import { useState, useEffect } from 'react';
import { ref, onChildChanged } from 'firebase/database';
import { db } from '../../config/firebase';
import ReactPlayer from 'react-player';
import { Container, Grid } from '@mui/material';
import Chat from '../../components/Chat';
import ValidateSession from '../../components/ValidateSession';
import { download_file, downloadFile, getCollectionsComplete, getDate, updateData } from '../../assets/js/functions';
import logo from '../../assets/images/header/Logo.svg';
import banner from '../../assets/images/streaming/Banner02.svg';
import './styles.scss';
import { reporteChats } from '../../assets/js/reportes';

export default function Streaming() {
	const [streaming, setStreaming] = useState({});
	const [usuario, setUsuario] = useState({});

	useEffect(() => {
		getInfo();
		const dbRef = ref(db, `${process.env.REACT_APP_BD_CONFIG}/`);
		onChildChanged(dbRef, (data) => {
			getInfo();
		});
	}, []);

	const getInfo = async () => {
		let json = {};
		const dataStreaming = await getCollectionsComplete(`${process.env.REACT_APP_BD_CONFIG}`);
		const dataUsuario = await getCollectionsComplete(`${process.env.REACT_APP_BD_USUARIOS}/${sessionStorage.getItem('mx')}`);
		if (dataStreaming.cambiar === false) {
			json = {
				...dataStreaming,
				url: dataStreaming.vimeo,
			};
		} else {
			json = {
				...dataStreaming,
				url: dataStreaming.respaldo,
			};
		}

		setStreaming(json);
		setUsuario(dataUsuario);
	};

	const descarga = () => {
		updateData(`${process.env.REACT_APP_BD_USUARIOS}`, `${sessionStorage.getItem('mx')}`, { descarga2: getDate() }).then(() => {
			download_file('https://lideresengestionremotasura.spira.com.mx/DG_INFO_GESTION_REMOTA_sesion%202.pdf', 'Infografía Gestión Remotaa');
		});
	};

	return (
		<>
			<ValidateSession>
				<div id='streaming'>
					<div className='header'>
						<img src={logo} alt='logo' />
					</div>
					<Grid container justifyContent='center' alignItems='center' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} data-aos='fade-up' data-aos-delay='0' data-aos-duration='1000'>
							<img src={banner} alt='banner' className='full' />
						</Grid>
					</Grid>
					<div className='linea' />
					<Container maxWidth='lg'>
						<Grid container direction='row' justifyContent='stretch' spacing={2}>
							<Grid item lg={8} md={8} sm={8} xs={12} data-aos='zoom-in' data-aos-delay='100' data-aos-duration='1000'>
								{!streaming.cambiar && <ReactPlayer url={`${streaming.url}`} controls={false} width='100%' height='420px' loop={false} playing={true} sandbox='allow-scripts allow-same-origin' />}
								{streaming.cambiar && (
									<div className='contenedor-streaming'>
										<iframe title='streaming' src={`${streaming.url}`} allow='autoplay; fullscreen' allowFullScreen className='estilo-streaming' />
									</div>
								)}
							</Grid>
							<Grid item lg={4} md={4} sm={4} xs={12} data-aos='zoom-in' data-aos-delay='50' data-aos-duration='1000'>
								<Chat usuario={usuario} idConferencia={`sdfdsf800fds8`} />
							</Grid>
							{streaming.descargar && (
								<Grid item xs={12} sm={12} md={12} lg={12} className='center'>
									<button onClick={() => descarga()}>Descargar</button>
								</Grid>
							)}
						</Grid>
					</Container>
				</div>
			</ValidateSession>
		</>
	);
}
